import axios from 'axios'
import * as R from 'ramda'
import moment from 'moment'
import TemplateForm from './../domain/TemplateForm'

const store = {
    namespace: false,
    state: {
        files: {},
        lastKey: null,
    },
    getters: {
        'om_filedrops'(state) {
            return state.files
        },
    },
    mutations: {
        SET_DROPFILES(state, dropfiles) {
            state.files = {
                ...state.files,
                ...R.pipe(
                    R.map(file => {
                        if (file.Status == 'ON_HOLD' && moment(file.details.on_hold.duedate, 'DD/MM/YYYY').isBefore()) {
                            file.details.on_hold.isOverdue = true
                        }

                        return file
                    })
                )(dropfiles)
            }
        },
        SET_LASTKEY(state, lastKey) {
            state.lastKey = lastKey || null
        },
        removeDropfile(state, drop_id) {
            state.files = R.omit([drop_id],state.files)
        }
    },
    actions: {
        'om.dropfile.find'(context, drop_id){
            return axios.get(context.getters.api(`/obase/dropfiles/${drop_id}`)).then(res => {
                const dropfiles = R.mergeAll([context.state.files,
                    {
                        [res.data.dropfile.PK] : res.data.dropfile
                    }
                ])

                context.commit('SET_DROPFILES', dropfiles)
            })
        },
        'om.dropfile.list'(context) {

            const url = context.getters.api(`/obase/dropfiles/my?limit=50&lastKey=${encodeURI(JSON.stringify(context.state.lastKey))}`)

            return axios.get(url).then(res => {
                context.commit('SET_LASTKEY', res.data.lastKey)
                context.commit('SET_DROPFILES', R.indexBy(R.prop('drop_id'), res.data.dropfiles))
            })
        },
        'om.dropfile.resetKey'(context) {
            context.commit('SET_LASTKEY', null)
        },

        'om.dropfile.loadMore'(context) {
            return context.dispatch('om.dropfile.all')
        },

        'om.dropfile.all'(context) {
            return axios.get(context.getters.api(`/obase/dropfiles?limit=50&lastKey=${encodeURI(JSON.stringify(context.state.lastKey))}`)).then(res => {
                context.commit('SET_LASTKEY', res.data.lastKey)
                context.commit('SET_DROPFILES', R.indexBy(R.prop('drop_id'), res.data.dropfiles))
            })
        },

        async 'om.dropfile.drop'(context, {file}) {
            const dropfile = await TemplateForm.validate(file) // throw error if not valid
            const droprequest = await axios.post(context.getters.api('/obase/dropfiles'), dropfile).then(res => {
                return res.data.dropfile
            })

            console.log("DROPREQUEST", droprequest)
            
            // upload the file and associate
            const attachment = await context.dispatch('om.attachments.upload', {
                file,
                resource: droprequest,
                attachment_type: 'dropfile'
            })

            return {
                file
            }
        },

        async 'om.dropfile.remove'(context, { drop_id }) {
            await axios.delete(context.getters.api(`/obase/dropfiles/${drop_id}`)).then(() => {
                context.commit('removeDropfile', drop_id)
            })
        },

        'om.dropfile.reject'(context, { drop_id, reason }) {
            return axios.post(context.getters.api(`/obase/dropfiles/reject`), {
                drop_id,
                reason
            }).then((res) => {    
                const dropfile = res.data.dropfile
                const data = {
                    [drop_id]: dropfile
                }
                context.commit('SET_DROPFILES', data)
                return dropfile
            })
            
        },
        
        'on.dropfile.hold'(context, { drop_id, reason, duedate }) {
            return axios.post(context.getters.api(`/obase/dropfiles/hold`), {
                drop_id, reason, duedate
            }).then((res) => {
                const dropfile = res.data.dropfile
                const data = {
                    [drop_id]: dropfile
                }

                context.commit('SET_DROPFILES', data)
                return dropfile
            })
        },

        'om.dropfile.release'(context, {drop_id}) {
            return axios.post(context.getters.api(`/obase/dropfiles/holdRelease`), {
                drop_id
            }).then((res) => {
                const dropfile = res.data.dropfile
                const data = {
                    [dropfile.drop_id]: dropfile
                }

                context.commit('SET_DROPFILES', data)
                return dropfile
            })
        },

        'om.dropfile.onholds'(context) {
            return axios.get(context.getters.api(`/obase/dropfiles/onhold`)).then(res => {
                return R.indexBy(R.prop('drop_id'), res.data.dropfiles)
            }).then(dropfiles => {
                context.commit('SET_DROPFILES', dropfiles)
                return dropfiles
            })
        },

        'om.dropfile.download'(context, { drop_id }) {
            return axios.get(context.getters.api(`/obase/dropfiles/${drop_id}?download=true`)).then(res => res.data.signed_url)
        },

        'om.dropfile.getJobsheet'(context, drop_id) {
            return axios.get(context.getters.api(`/obase/dropfiles/${drop_id}`)).then(res => res.data.dropfile)
        }
    }
}

export default store